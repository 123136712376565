import React from "react"

import css from "./DocumentInfo.module.scss"

const DocumentInfo = ({ url, title, creator, callNumber }) => {
  return (
    <dl className={css.documentInfo}>
      {title && (
        <div className={css.documentInfoSection}>
          <dt className={css.documentInfoLabel}>Title</dt>
          <dd className={css.documentInfoValue}>
            <a href={url} rel="noopener noreferrer" target="_blank">
              {title}
            </a>
          </dd>
        </div>
      )}
      {creator && (
        <div className={css.documentInfoSection}>
          <dt className={css.documentInfoLabel}>Creator</dt>
          <dd className={css.documentInfoValue}>{creator}</dd>
        </div>
      )}
      {callNumber && (
        <div className={css.documentInfoSection}>
          <dt className={css.documentInfoLabel}>Call number</dt>
          <dd className={css.documentInfoValue}>
            <a href={url} rel="noopener noreferrer" target="_blank">
              {callNumber}
            </a>
          </dd>
        </div>
      )}
    </dl>
  )
}

export default DocumentInfo
