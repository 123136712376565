import React from "react"
import { StaticQuery, graphql } from "gatsby"

import css from "./Thumbnail.module.scss"

const Thumbnail = ({ filename, title, linkUrl = "" }) => {
  const filePath = ["thumbs", `${filename}.jpg`]
  const render = data => {
    const url = [data.site.siteMetadata.baseAssetUrl.replace(/\/$/, "")]
      .concat(filePath)
      .join("/")
    const img = <img className={css.image} src={url} alt={title} />
    if (linkUrl.length) {
      return (
        <a
          className={[css.thumbnail].join(" ")}
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {img}
        </a>
      )
    }
    return img
  }

  return (
    <StaticQuery
      query={graphql`
        query ThumbnailQuery {
          site {
            siteMetadata {
              baseAssetUrl
            }
          }
        }
      `}
      render={render}
    />
  )
}

export default Thumbnail
