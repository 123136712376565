import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import DocumentInfo from "../components/DocumentInfo"
import Thumbnail from "../components/Thumbnail"

import { slugify } from "../../lib/Path"

import css from "./Parliamentarian.module.scss"

import LinkData from "../data/parliamentarian_links.json";

const PartyLink = ({
  description = '',
  dateStart = '',
  dateEnd = '',
}) => {
  if (description.length === 0) {
    return null;
  }
  const dateStr = [dateStart, dateEnd].filter(x => x && x.length > 0).join(' - ');
  return (
    <Link to={`/party/${slugify(description)}`}>
      {description}
      {description.length > 0 && dateStr.length > 0 && <br />}
      {dateStr}
    </Link>
  );
};

const ElectorateInfo = ({
  mla = '',
  mlc = '',
  description = '',
  dateStart = '',
  dateEnd = '',
}) => {
  const dateStr = [dateStart, dateEnd].filter(x => x && x.length > 0).join(' - ');
  return (
    <>
      {mla}
      {mlc}
      {((mla && mla.length > 0) || (mlc && mlc.length > 0)) && <br />}
      {description.length > 0 && (
        <Link to={`/electorates/${slugify(description)}/`}>
          {description}
        </Link>
      )}
      {description.length > 0 && dateStr.length > 0 && ', '}
      {dateStr}
    </>
  );
};

const ParlHouseLink = ({ parliamentarian = {} }) => {
  let parlLink = "";
  const id = parliamentarian.id || 0;
  if (parliamentarian.parliamentLink || false) {
    const surnameSanitised = parliamentarian.familyName
      .replace(/\([^\)]+\)/g, '')
      .replace(/(^\s+|\s+$)/g, '')
      .replace(/["']/g, '')
      .replace(/\s+/g, "-")
      .replace(/\.+/g, '.')
      .toLowerCase();
    const givenSanitised = parliamentarian.otherNames
      .replace(/\([^\)]+\)/g, '')
      .replace(/(^\s+|\s+$)/g, '')
      .replace(/["']/g, '')
      .replace(/\s+/g, "-")
      .replace(/\.+/g, '.')
      .toLowerCase();
    parlLink = `https://www.parliament.nsw.gov.au/members/Pages/profiles/${surnameSanitised}_${givenSanitised}.aspx`
  }
  if (LinkData[id] && LinkData[id].nsw_parl) {
    parlLink = LinkData[id].nsw_parl;
  }
  if (!parlLink.length) {
    return [];
  }
  return (
    <li key="parlhouse">
      <a
        href={parlLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        Members of Parliament, Parliament of NSW
      </a>
    </li>
  );
};

export default ({ data }) => {
  const parl = data.parliamentariansCsv
  const parliamentarian = parl.fields || {}
  const id = parliamentarian.id || 0;
  const biogEdges = parl.biogs || []
  const papers = parl.papers || []
  const parties = (parl.parties || []).filter(
    party => party.fields.description && party.fields.description.length
  )
  const electorates = parl.electorates || []

  const parlName = [
    parliamentarian.familyName,
    ", ",
    parliamentarian.title,
    " ",
    parliamentarian.otherNames,
    ", ",
    parliamentarian.dateBirth,
    " – ",
    parliamentarian.dateDeath,
  ]
    .filter(x => x && x.length)
    .join("")

  const thumbs = (parl.thumbnails || [])
    .filter(thumb => thumb.fields.name !== "none")
    .map(thumb => (
      <Thumbnail
        key={thumb.fields.name}
        filename={thumb.fields.name}
        linkUrl={thumb.adlib.length ? thumb.adlib[0].primoUrl : ""}
        title={parlName}
      />
    ))

  return (
    <Layout>
      <HeadMeta pageTitle={parlName} />
      <PageHeader>{parlName}</PageHeader>
      <Breadcrumb
        route={[
          { name: "Parliamentary Papers", href: "/" },
          { name: "NSW Parliamentarians", href: "/parliamentarians/" },
          { name: parlName, href: `/${parliamentarian.slug}` },
        ]}
      />
      <main>
        <aside className={css.thumbnails}>{thumbs}</aside>
        {biogEdges.length > 0 && (
          <>
            <h2>Biographical notes</h2>
            {biogEdges.map((biog, key) => (
              <div
                key={`biog-${key}`}
                dangerouslySetInnerHTML={{
                  __html: biog.fields.body,
                }}
              />
            ))}
          </>
        )}
        {papers.length > 0 && (
          <>
            <h2>Papers</h2>
            <ul className={css.paperList}>
              {papers.map((paper, key) => (
                <li key={`papers-${key}`}>
                  <DocumentInfo
                    title={paper.fields.recordTitle}
                    url={
                      paper.adlib.length
                        ? paper.adlib[0].primoUrl
                        : `https://collection.sl.nsw.gov.au/search?search=${encodeURIComponent(paper.fields.recordNumber)}`
                    }
                    creator={parlName}
                    callNumber={paper.fields.recordNumber}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        <h2>Political service</h2>
        {parties.length > 0 && (
          <>
            <h3>Parties</h3>
            <ul>
              {parties.map((party, key) => (
                <li key={`party-${key}`}>
                  <PartyLink
                    description={party.fields.description || ''}
                    dateStart={party.fields.dateStart || ''}
                    dateEnd={party.fields.dateEnd || ''}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        {electorates.length > 0 && (
          <>
            <h3>Electorates</h3>
            <ul>
              {electorates.map((elec, key) => (
                <li key={`party-${key}`}>
                  <ElectorateInfo
                    mla={elec.fields.mla || ''}
                    mlc={elec.fields.mlc || ''}
                    description={elec.fields.description || ''}
                    dateStart={elec.fields.dateStart || ''}
                    dateEnd={elec.fields.dateEnd || ''}
                  />
                </li>
              ))}
            </ul>
          </>
        )}
        <h2>Links</h2>
        <ul>
          <ParlHouseLink parliamentarian={parliamentarian} />
        </ul>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($parliamentarianId: String!) {
    parliamentariansCsv(fields: { id: { eq: $parliamentarianId } }) {
      fields {
        id
        familyName
        title
        otherNames
        dateBirth
        dateDeath
        parliamentLink
        slug
      }
      thumbnails {
        fields {
          name
        }
        adlib {
          primoUrl
        }
      }
      papers {
        fields {
          recordNumber
          recordTitle
          recordLink
        }
        adlib {
          primoUrl
        }
      }
      parties {
        fields {
          description
          dateStart
          dateEnd
        }
      }
      electorates {
        fields {
          mla
          mlc
          description
          dateEnd
          dateStart
        }
      }
      biogs {
        fields {
          body
          order
        }
      }
    }
  }
`
